import React, {useState } from "react";

const NumberDropdown = ({ min, max, step, defaultValue, onChange }) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue);
  
    // Generate the numbers for the dropdown
    const generateOptions = () => {
        const options = [];
        for (let i = min; i <= max; i += step) {
            options.push(i);
        }
        return options;
    };
  
    const handleChange = (event) => {
        const value = Number(event.target.value);
        setSelectedValue(value);
        if (onChange) onChange(value); // Notify parent of the change
    };
  
    return (
        <div className="numDropdown">
            Secs before mute/unmute
            <select value={selectedValue} onChange={handleChange}>
                {generateOptions().map((number) => (
                    <option key={number} value={number}>
                        {number}
                    </option>
                ))}
            </select>
        </div>

    );
};
  
export default NumberDropdown;
