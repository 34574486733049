import React, { useRef, useState, useEffect } from "react";
// import sound1 from '../sounds/ex1.mp3';
// import sound2 from '../sounds/ex2.mp3';
// import orcs from '../sounds/orcs.wav';
import yeah from '../sounds/yeah.wav';
import base from '../sounds/base.wav';
import strings from '../sounds/strings.wav';
import tripod from '../sounds/tripod.mp3';
import bugs from '../sounds/bugs.mp3';

import NN1 from '../sounds/camden/neon/Neon_Noir_1_Pulse.m4a';
import NN2 from '../sounds/camden/neon/Neon_Noir_2_choir.m4a';
import NN3 from '../sounds/camden/neon/Neon_Noir_3_pulse_lead.m4a';
import NN4 from '../sounds/camden/neon/Neon_Noir_4_piano.m4a';
import NN5 from '../sounds/camden/neon/Neon_Noir_5_bass.m4a';

import RB1 from '../sounds/camden/rebirth/Rebirth_1.m4a';
import RB2 from '../sounds/camden/rebirth/Rebirth_2_lead_synth.m4a';
import RB3 from '../sounds/camden/rebirth/Rebirth_3_bass.m4a';
import RB4 from '../sounds/camden/rebirth/Rebirth_4_reverse_piano.m4a';

import F1 from '../sounds/camden/forest/Forest_1.m4a';
import F2 from '../sounds/camden/forest/Forest_2.m4a';
import F3 from '../sounds/camden/forest/Forest_3_lead.m4a';
import F4 from '../sounds/camden/forest/Forest_4_choir.m4a';
import F5 from '../sounds/camden/forest/Forest_5_bass.m4a';
import F6 from '../sounds/camden/forest/Forest_6_nature.m4a';

import '../style/Mixer.css'
import { Slider } from "@mui/material";
import VolumeControls from "./VolumeControls";
import NumberDropdown  from "./NumberDrop";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

function calcActualVolume(masterVolume, childVolume) {
    // Normalize the volumes (convert to 0-1 range)
    const master = masterVolume / 100;
    const child = childVolume / 100;
  
    // Calculate the effective volume
    const effectiveVolume = master * child * 100; // Convert back to percentage
  
    return effectiveVolume;
  }

const AudioPlayer = ({ audioSrc, label, delay, playing, type, masterVolume}) => {
    const defaultVolume = 15
    const audioRef = useRef(new Audio(audioSrc));
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(type === "main" ? calcActualVolume(masterVolume, defaultVolume) : 0);
    const [isMuted, setIsMuted] = useState(type !== "main");
    const [info, setInfo] = useState(type !== "main" ? "muted (0)" : "");
    const [lastVolume, setLastVolume] = useState(defaultVolume);    

    useEffect(() => {
        const audio = audioRef.current;
        audioRef.current.loop = true;
        audioRef.current.volume = volume/100;

        const updateDuration = () => setDuration(audio.duration);
        const updateCurrentTime = () => setCurrentTime(audio.currentTime);

        audio.addEventListener("loadedmetadata", updateDuration);
        audio.addEventListener("timeupdate", updateCurrentTime);

        return () => {
            audio.removeEventListener("loadedmetadata", updateDuration);
            audio.removeEventListener("timeupdate", updateCurrentTime);
        };
    }, []);


    useEffect(() => {
        const audio = audioRef.current;
        playing ? audio.play() : audio.pause();
    }, [playing]);


    //Handle muting at certain intervals
    useEffect(() => {
        const audio = audioRef.current;
        let secs = Math.floor(currentTime)

        if(type === "main" || (secs % delay === 0 && currentTime !== 0)) {
            let i = (isMuted ? "muted" : "playing" + " (" + Math.floor(currentTime) + ")")
            setInfo(i)
            audio.muted = isMuted;
        }
    }, [currentTime]);

    useEffect(() => {
        // Set the audio volume whenever it changes
        audioRef.current.volume = calcActualVolume(masterVolume, volume)/100;
        setIsMuted(volume === 0)
    }, [volume, masterVolume]);

    const handleSliderChange = (value) => {
        const audio = audioRef.current;
        audio.currentTime = value; // Seek to the selected time
        setCurrentTime(value);
    };

    const toggleMute = () => {
        if(isMuted)
            setVolume(lastVolume)
        else {
            setLastVolume(volume)
            setVolume(0)

        }

        setIsMuted(!isMuted)
    };

    return (
        <div className="audioFlex">
            <div>
                <h4>{label}</h4>
                <VolumeControls isMuted={isMuted} toggleMute={toggleMute} volume={volume} setVolume={setVolume}/>
            </div>
           

            <span>
                {Math.floor(currentTime)} 
            </span>
            <div style={{width: '100%'}}>
                {info}
                <Slider
                    defaultValue={0}
                    valueLabelDisplay="auto"
                    marks
                    steps={1}
                    min={0}
                    max={duration-1}
                    value={Math.floor(currentTime)}
                    onChange={(_, value) => handleSliderChange(Number(value))}
                />
            </div>

            <span>
                {Math.floor(duration)} 
            </span>
           
        </div>
    );
};

const audioSources1 = [
    { audioSrc: RB1, label: "Rebirth_1" },
    { audioSrc: RB2, label: "Rebirth_2_lead_synth" },
    { audioSrc: RB3, label: "Rebirth_3_bass" },
    { audioSrc: RB4, label: "Rebirth_4_piano" },
];

const audioSources2 = [
    { audioSrc: F1, label: "Forest_1" },
    { audioSrc: F2, label: "Forest_2" },
    { audioSrc: F3, label: "Forest_3_lead" },
    { audioSrc: F4, label: "Forest_4_choir" },
    { audioSrc: F5, label: "Forest_5_bass" },
    { audioSrc: F6, label: "Forest_6_nature" },
  
];

const audioSources3 = [
    { audioSrc: NN1, label: "Neon Pulse" },
    { audioSrc: NN2, label: "Neon Choir" },
    { audioSrc: NN3, label: "Neon Pulse Lead" },
    { audioSrc: NN4, label: "Neon Piano" },
    { audioSrc: NN5, label: "Neon Bass" },
  
];

const audioSources4 = [
    { audioSrc: yeah, label: "yeah" },
    { audioSrc: base, label: "base" },
    { audioSrc: strings, label: "strings" },
    { audioSrc: tripod, label: "tripod" },
    { audioSrc: bugs, label: "bugs" }
];

const titles = ["Rebirth", "Forest", "Neon Noir", "Nic's Bad Sounds"];
const audioSourceMasterList = [audioSources1, audioSources2, audioSources3, audioSources4];

const AudioPlayerWithSliders = () => {
    const [playing, setPlaying] = useState(false);
    const [delay, setDelay] = useState(5);

    const [volume, setVolume] = useState(100);
    const [isMuted, setIsMuted] = useState(false);
    const [lastVolume, setLastVolume] = useState(false);

    const toggleMute = () => {
        if(isMuted)
            setVolume(lastVolume)
        else {
            setLastVolume(volume)
            setVolume(0)
        }

        setIsMuted(!isMuted)
    };

    return (
        <div className="mixerContainer">
            <div className="controlsContainer">
                <div className="controls">
                    <button onClick={() => setPlaying(!playing)}>{playing ? <>⏸️</> : <>▶️</>}</button>
                    <NumberDropdown min={0} max={30} step={1} defaultValue={delay} onChange={setDelay}/> 
                </div>
                <div className="controls">
                    <VolumeControls isMuted={isMuted} toggleMute={toggleMute} volume={volume} setVolume={setVolume}/>
                </div>
            </div>
            <div className="containerFlex">
            <Tabs>
                <TabList>
                    {titles.map((title) => (
                        <Tab>{title}</Tab>
                    ))}
                </TabList>
                {audioSourceMasterList.map((audioSources) => (
                    <TabPanel>
                        {audioSources.map(({ audioSrc, label }, index) => (
                            <AudioPlayer
                                key={index}
                                audioSrc={audioSrc}
                                delay={delay}
                                masterVolume={volume}
                                label={label}
                                playing={playing}
                                type={index === 0 ? "main" : (index + 1).toString()} // "main" for the first, numbers for the rest
                            />
                        ))}
                    </TabPanel>
                ))}
            </Tabs>
            </div>
        </div>

    );
};

export default AudioPlayerWithSliders;