import * as React from 'react';
import Slider from '@mui/material/Slider';

export default function VolumeControls({toggleMute, isMuted, volume, setVolume}) {

    const handleSliderChange = (event, newVolume) => {
        setVolume(newVolume);
    };

    const handleBlur = () => {
        if (volume < 0) {
            setVolume(0);
        } else if (volume > 100) {
            setVolume(100);
        }
    };

    return (
        <div className='volumeFlex' onBlur={handleBlur}>
            <button onClick={toggleMute}>
                {isMuted ? <>🔇</> : <>🔊</>}
            </button>
            <Slider
                value={typeof volume === 'number' ? volume : 0}
                onChange={handleSliderChange}
                aria-labelledby="input-slider"
            />
        </div>
    );
}
